import { getNestedValue, setNestedValue } from './utils/index.js'
import { createMallTagsInfo, createBeltLabel, createPriceRelatedData, createDiscountInfo, createHistoricalPrice, createLocateLabels, createRankList, createSellingPointUniversalLabels, createHorseRaceLabelData, createServiceLabelsData, createTrendLabel, createSizeStock, createSaleAttr, createReducePrice, createSuggestedSalePriceConfigInfo, createMixPromotionInfo, createQuantity, createSoldOut } from './childDataTrans.js'


// 对应web中间层以及bff层的商品数据Key值
// key: bff层  value: web中间层

// 忽略的字段（商卡不用）： detail_url  appPromotion score  brand

// 未处理的字段：cccFeedback isHypernymGoods
const goodsTransformationKeys = [
  // [bffKey  webKey]
  ['ext', 'ext'],
  ['mall_code', 'mall_code'],
  ['goods_id', 'goods_id'],
  ['goods_sn', 'goods_sn'],
  ['goods_img', 'goods_img'],
  ['goods_thumb', 'goods_img_thumb'],
  ['goods_name', 'goods_name'],
  ['goods_url_name', 'goods_url_name'],
  ['productRelationID', 'productRelationID'],
  ['cat_id', 'cat_id'],
  ['cate_name', 'cat_name'],
  ['parent_id', 'parent_id'],
  ['detail_image', 'detail_image'],
  ['detail_url', 'detail_url'],
  ['dynamic_ext', 'dynamic_ext'],
  ['is_on_sale', 'is_on_sale'],
  ['stock', 'stock'],
  ['store_code', 'store_code'],
  ['isNewCoupon', 'isNewCoupon'],
  ['promotionInfo', 'promotionInfo'],
  ['count', 'count'],
  ['isHasBigSize', 'is_show_plus_size'],
  ['mallPromotionInfo', 'mallPromotionInfo'],
  ['mallInfoList', 'mallInfoList'],

  // 是否显示加车按钮
  ['productMaterial.showAddButtonLabel', 'showAddButtonLabel'],
  ['productMaterial.showAddButtonLabelStyle', 'showAddButtonLabelStyle'],

  // 标题前角标 start
  ['productMaterial.productTitleSubscript.labelLang', 'pcStandardView.goodsNameLabel.labelLang'],
  ['productMaterial.productTitleSubscript.fontColor', 'pcStandardView.goodsNameLabel.fontColor'],
  ['productMaterial.productTitleSubscript.backgroundColor', 'pcStandardView.goodsNameLabel.backgroundColor'],
  ['productMaterial.productTitleSubscript.appTraceInfo', 'pcStandardView.goodsNameLabel.appTraceInfo'],
  ['productMaterial.productTitleSubscript.labelLang', 'pcBroadwiseView.goodsNameLabel.labelLang'],
  ['productMaterial.productTitleSubscript.fontColor', 'pcBroadwiseView.goodsNameLabel.fontColor'],
  ['productMaterial.productTitleSubscript.backgroundColor', 'pcBroadwiseView.goodsNameLabel.backgroundColor'],
  ['productMaterial.productTitleSubscript.appTraceInfo', 'pcBroadwiseView.goodsNameLabel.appTraceInfo'],
  // 标题前角标 end


  // 价格相关 start
  ['discountPrice', 'discountPrice'],
  ['unit_discount', 'unit_discount'],
  ['salePrice', 'salePrice'],
  ['retailPrice', 'retailPrice'],
  ['suggestedSalePriceInfo', 'suggestedSalePriceInfo'],
  ['isInversion', 'isInversion'],  // 是否倒挂
  ['suggestedSalePrice', 'suggestedSalePrice'],  // 合规价
  ['productMaterial.hisLowPriceLabel', createHistoricalPrice], // 最低价
  // 价格相关 end

  // 销量标签
  ['productMaterial.salesLabel.backgroundColor', 'pcStandardView.salesLabels.backgroundColor'],
  ['productMaterial.salesLabel.fontColor', 'pcStandardView.salesLabels.fontColor'],
  ['productMaterial.salesLabel.labelLang', 'pcStandardView.salesLabels.labelLang'],
  ['productMaterial.salesLabel.appTraceInfo', 'pcStandardView.salesLabels.appTraceInfo'],
  ['productMaterial.salesLabel.backgroundColor', 'pcBroadwiseView.salesLabels.backgroundColor'],
  ['productMaterial.salesLabel.fontColor', 'pcBroadwiseView.salesLabels.fontColor'],
  ['productMaterial.salesLabel.labelLang', 'pcBroadwiseView.salesLabels.labelLang'],
  ['productMaterial.salesLabel.appTraceInfo', 'pcBroadwiseView.salesLabels.appTraceInfo'],

  // buybox入口
  ['productMaterial.buyBox.lowestPrice', 'buyBoxPrice.price'],
  ['productMaterial.buyBox.sameKindNum', 'buyBoxCount.count'],

  // 收藏用  
  ['skuAttrList', 'sku_attr_list'],
  ['inventory', 'inventory'],
  ['wishlistId', 'wishlistId'],
  ['sale_status', 'sale_status'],
  ['isSize', 'isSize'],
  ['count', 'count'],
  ['sku_code', 'sku_code'],
  ['originalAttrValueId', 'attrValueId'],
  // 浏览人数标签-弹幕组件
  ['productMaterial.numberOfVisitorsLabel', 'pretreatInfo.numberOfVisitorsLabel'],
  // 收藏轮播
  ['productMaterial.popularLabelList', 'pretreatInfo.popularLabelList'],
]

/**
 * 
 * @param {*} bffGoodsInfo 
 * @param {{
 *  lang: String //当前站点语言
 *  language: Object //多语言
 *  isPaid: Boolean //是否付费会员
 * }} options 
 * @returns goodsInfo
 */
export const goodsTransformation = (bffGoodsInfo = {}, options = {}) => {
  const goodsInfo = {
    // 标识，商卡内部需要针对两端不同的特殊处理
    bffGoodsInfo: true,
    pcStandardView: {},
    pcBroadwiseView: {},
    pretreatInfo: {
      pcStandardView: {},
      pcBroadwiseView: {}
    }
  }

  goodsTransformationKeys.forEach(([bffKey, webKey]) => {
    const bffValue = getNestedValue(bffGoodsInfo, bffKey)

    if (bffValue !== undefined && bffValue !== null) {
      if (Object.prototype.toString.call(webKey) === '[object Function]') {
        webKey(bffGoodsInfo, goodsInfo)
      } else {
        setNestedValue(goodsInfo, webKey, bffValue)
      }
    }
  })

  // 收藏找相似弹窗字段处理
  if (bffGoodsInfo?.similar_products?.length) {
    goodsInfo.similar_products = bffGoodsInfo.similar_products.map(v => goodsTransformation(v, options))
  }
  
  // mall信息
  if (bffGoodsInfo.mallInfoList?.length > 0 && bffGoodsInfo.mall_code) {
    const mallTagsInfoRes = createMallTagsInfo(bffGoodsInfo)

    goodsInfo.pretreatInfo.mallTagsInfo = mallTagsInfoRes?.mallTagsInfo
    goodsInfo.pretreatInfo.localMallReady = mallTagsInfoRes?.localMallReady
  }

  // 主图角标处理
  const locateLabels = createLocateLabels(bffGoodsInfo)
  goodsInfo.pcStandardView.locateLabels = locateLabels.pcStandardView
  goodsInfo.pcBroadwiseView.locateLabels = locateLabels.pcBroadwiseView

  // 活动价格的处理 到手价 闪购价 会员价
  const priceData = createPriceRelatedData(bffGoodsInfo, options)

  goodsInfo.estimatedPriceInfo = priceData.estimatedPriceInfo ?? {}
  goodsInfo.sheinClubPromotionInfo = priceData.sheinClubPromotionInfo ?? {}
  goodsInfo.pretreatInfo.flashZonePromotion = priceData.flashZonePromotion ?? {}
  goodsInfo.pretreatInfo.newFlashPromotion = priceData.newFlashPromotion ?? {}
  // 是否命中普通闪购商品
  goodsInfo.pretreatInfo.flashsale = priceData?.flashsale ?? 0

  // 折扣标签
  goodsInfo.pretreatInfo.discountInfo = createDiscountInfo(bffGoodsInfo, goodsInfo, options)

  // 主图腰带处理
  const beltLabel = createBeltLabel(bffGoodsInfo, goodsInfo, options)
  goodsInfo.pretreatInfo.pcStandardView.beltLabel = beltLabel.pcStandardView
  goodsInfo.pretreatInfo.pcBroadwiseView.beltLabel = beltLabel.pcBroadwiseView

  // 榜单处理
  const rankListData = createRankList(bffGoodsInfo)

  goodsInfo.pretreatInfo.pcStandardView.rankingList = rankListData.pcStandardView
  goodsInfo.pretreatInfo.pcBroadwiseView.rankingList = rankListData.pcBroadwiseView


  // 卖点轮播处理
  if (bffGoodsInfo.productMaterial?.productAttributeLabelList?.length > 0) {
    const sellingPointUniversalLabels = createSellingPointUniversalLabels(bffGoodsInfo)
    goodsInfo.pretreatInfo.pcStandardView.sellingPointUniversalLabels = sellingPointUniversalLabels.pcStandardView
    goodsInfo.pretreatInfo.pcBroadwiseView.sellingPointUniversalLabels = sellingPointUniversalLabels.pcBroadwiseView
  }

  // 赛马标签轮播处理
  if (bffGoodsInfo.productMaterial?.v2ProductAttributeLabelList?.length > 0) {
    goodsInfo.pretreatInfo.horseRaceLabel = createHorseRaceLabelData(bffGoodsInfo)
  }

  // 客户端预处理中的促销标签字段 相关处理（参见public/src/services/goodsItemInfo/goodsPretreatInfo.js 中的setPromotionLabel）
  let mixPromotionInfo, promotionReady
  if(bffGoodsInfo?.promotionInfo?.length > 0) {
    ({ mixPromotionInfo, promotionReady } = createMixPromotionInfo(bffGoodsInfo))
    goodsInfo.pretreatInfo.mixPromotionInfo = mixPromotionInfo
    goodsInfo.pretreatInfo.promotionReady = promotionReady
  }

  // 服务类标签处理
  if (bffGoodsInfo.productMaterial?.productServiceLabelList?.length > 0) {
    const serviceLabels = createServiceLabelsData(bffGoodsInfo, { mixPromotionInfo })

    goodsInfo.pretreatInfo.pcStandardView.serviceLabels = serviceLabels
    goodsInfo.pretreatInfo.pcBroadwiseView.serviceLabels = serviceLabels
  }

  // 趋势词 时尚店铺处理
  if (bffGoodsInfo.productMaterial?.trendLabel?.trendType) {
    const trendLabel = createTrendLabel(bffGoodsInfo, options)

    if (trendLabel.storeInfo) {
      goodsInfo.pretreatInfo.storeInfo = Object.assign({}, goodsInfo.pretreatInfo.storeInfo, trendLabel.storeInfo)
    }
    if (trendLabel.deliveryInfo) {
      goodsInfo.deliveryInfo = trendLabel.deliveryInfo
    }
  }

  // 合规站点处理
  if (bffGoodsInfo.suggestedSaleType) {
    goodsInfo.pretreatInfo.suggestedSalePriceConfigInfo = createSuggestedSalePriceConfigInfo(bffGoodsInfo, options)
  }

  // 收藏处理部分 start
  if (bffGoodsInfo.sku_code && bffGoodsInfo.skuInventoryList?.length > 0) {
    const stockData = createSizeStock(bffGoodsInfo, options)
    goodsInfo.sizeStock = stockData.sizeStock
    goodsInfo.pretreatInfo.stockLeft = stockData.stockLeft
    goodsInfo.pretreatInfo.stockLeftReady = stockData.true
  }

  // 主图右下角尺码
  if (bffGoodsInfo.skuAttrStr) {
    goodsInfo.pretreatInfo.saleAttr = createSaleAttr(bffGoodsInfo)
  }

  // 降价标识
  if (Number(bffGoodsInfo.reducePrice?.amount || 0) > 0) {
    goodsInfo.pretreatInfo.reducePrice = createReducePrice(bffGoodsInfo, options)
  }

  goodsInfo.pretreatInfo.likeQuantity = createQuantity(bffGoodsInfo, options)

  // 收藏处理部分 end

  // 售罄
  goodsInfo.pretreatInfo.saleout = createSoldOut(goodsInfo, options)


  return goodsInfo
}

